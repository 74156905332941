
import Mobile from '@/layout/mobile/emptyMobile.vue'
import Sidebar from '@/layout/Sidebar.vue'

const personCenter = [
    {
        path: '/person',
        component: Mobile,
        children: [
            {
                path: 'personalCenter',
                name: 'MobileMy',
                component: () => import('#/viewsMobile/person/index.vue'),
                meta: { title: '我的', whitelist: true }
            },
            {
                path: 'm_personInfo',
                name: 'MobilePersonInfo',
                component: () => import('#/viewsMobile/person/personInfo.vue'),
                meta: { title: '个人资料' }
            },
            {
                path: 'm_personExamInfo',
                name: 'MobilePersonExamInfo',
                component: () => import('#/viewsMobile/person/personExamInfo.vue'),
                meta: { title: '个人资料' }
            },
            {
                path: 'm_personDrive',
                name: 'MobilePersonDrive',
                component: () => import('#/viewsMobile/person/personDrive.vue'),
                meta: { title: '个人资料' }
            },
            {
                path: 'm_personPhoto',
                name: 'MobilePersonPhoto',
                component: () => import('#/viewsMobile/person/personPhoto.vue'),
                meta: { title: '个人资料' }
            },
            {
                path: 'm_mycheck',
                name: 'MobileMycheck',
                component: () => import('#/viewsMobile/exam/myCheck.vue'),
                meta: { title: '我的约考' }
            },
            {
                path: 'm_myTest',
                name: 'MobileMyTest',
                component: () => import('#/viewsMobile/exam/myTest.vue'),
                meta: { title: '我的考试' }
            },
            {
                path: 'm_printAdmissionTicket/:printid',
                name: 'MobilePrintAdmissionTicket',
                component: () => import('#/viewsMobile/exam/printAdmissionTicket.vue'),
                meta: { title: '打印准考证' }
            },
            {
                path: 'm_cerSearch',
                name: 'MobileCersearch',
                component: () => import('#/viewsMobile/exam/cersearch.vue'),
                meta: { title: '证书查询' }
            },
            {
                path: 'm_scoreSearch',
                name: 'MobileScoreSearch',
                component: () => import('#/viewsMobile/exam/scoreSearch.vue'),
                meta: { title: '成绩查询' }
            },
            //  账号设置
            {
                path: 'm_utilp',
                name: 'MobileUtilp',
                component: () => import('@/viewsMobile/person/utilp/utilp.vue'),
                meta: { title: '账号设置' }
            },
            {
                path: 'm_modifyPassword',
                name: 'MobileModifyPassword',
                component: () => import('@/viewsMobile/person/utilp/modifyPassword.vue'),
                meta: { title: '修改密码' }
            },
            {
                path: 'm_bindOrModify/:type',
                name: 'MobileBindemail',
                component: () => import('@/viewsMobile/person/utilp/bindOrModify.vue')
            }
        ]
    },
    // 支付
    {
        path: '/m_paymentList',
        name: 'MobilePaymentList',
        children: [
            {
                path: '',
                component: () => import('#/viewsMobile/payment/paymentList.vue'),
                name: 'MobilePaymentList'
            }
        ]
    },
    {
        path: '/m_paymentRefresh',
        name: 'MobilePaymentRefresh',
        children: [
            {
                path: ':signupid/:orderid',
                component: () => import('#/viewsMobile/payment/paymentRefresh.vue'),
                name: 'MobilePaymentRefresh'
            }
        ]
    },
    {
        path: '/paymobile',
        name: 'MobilePayMobile',
        children: [
            {
                path: ':orderid',
                component: () => import('#/views/pufapay/mobile.vue'),
                name: 'MobilePayMobile'
            }
        ]
    },
    {
        path: '/dealPayInfo',
        name: 'MobileDealPayInfo',
        children: [
            {
                path: '',
                component: () => import('#/viewsMobile/payment/dealPayInfo.vue'),
                name: 'MobileDealPayInfo'
            }
        ]
    },
    {
        path: '/paymobile/:orderid',
        name: 'paymobile',
        meta: { title: '支付' },
        component: () => import('#/views/pufapay/mobile.vue')
    },

    // 考生须知
    {
        path: '/m_SignupNotice',
        meta: { whitelist: true },
        component: Mobile,
        children: [
            {
                path: ':type',
                name: 'MobileSignupNotice',
                component: () => import('#/viewsMobile/static/notice.vue')
            }
        ]
    },
    {
        path: '/m_contactus',
        meta: { whitelist: true },
        component: Mobile,
        children: [
            {
                path: '',
                name: 'MobileContactus',
                component: () => import('#/viewsMobile/static/contactus.vue')
            }
        ]
    },
    {
        path: '/m_complain',
        meta: { whitelist: true },
        component: Mobile,
        children: [
            {
                path: '',
                name: 'MobileComplain',
                component: () => import('#/viewsMobile/static/complain.vue')
            }
        ]
    }

]

export default personCenter
